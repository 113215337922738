<template>
  <div class="dishlibrary">
    <div v-for="group in data" :key="group.Id">
      <div v-if="group.dishes.length">
        <h2>{{ group.DisplayName }}</h2>

        <div class="flex flex-wrap">
          <button @click="onSelectDish(item)" class="w-25" v-for="item in group.dishes" :key="item.Id" style="max-width: 10rem">
            <figure class="no-margin">
              <img :src="getImage(item.Id)" :alt="item.DisplayName" v-if="getImage(item.Id)" />
              <figcaption>{{ item.DisplayName }}</figcaption>
            </figure>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { getDishImage } from '@/services/heatFlowData';
import { MODULE_NAME as DIALOG_MODULE } from '@/store/modules/dialog';

export default {
  name: 'DishLibrary',

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      sessionNumber: 'SESSION_NUMBER'
    })
  },

  data() {
    return {
      images: [],
      selectedDish: {}
    };
  },

  mounted() {
    const RESPONSES = [];
    this.$store.commit(`${DIALOG_MODULE}/SET_LOADING`, true);

    this.data.forEach(item => {
      if (item.dishes.length) {
        item.dishes.forEach(dish => {
          RESPONSES.push(this.requestImage(dish.Id));
        });
      }
    });

    axios
      .all(RESPONSES)
      .then(
        axios.spread(() => {
          this.$store.commit(`${DIALOG_MODULE}/SET_LOADING`, false);
        })
      )
      .finally(() => {
        this.$store.commit(`${DIALOG_MODULE}/SET_LOADING`, false);
      });
  },

  methods: {
    getImage(id) {
      const result = this.images.find(image => image.id === id);
      const { base64 } = result;
      return base64;
    },

    async requestImage(id) {
      try {
        const response = await getDishImage({ id, sessionNumber: this.sessionNumber });
        const data = get(response, 'data');

        // Add image to array
        this.images.push({
          id,
          base64: data
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },

    onSelectDish(dish) {
      this.selectedDish = Object.assign({}, dish);
      // Emit event to the main event bus
      this.$events.$emit('dishlibrary:select-dish', dish);
      // Close dialog
      this.$store.commit(`${DIALOG_MODULE}/CLOSE_DIALOG`);
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 0.625rem;
  margin-bottom: 0.625rem;

  &:hover {
    color: $c-brand;

    img {
      border-color: $c-brand;
    }
  }
}

figure {
  img {
    border: 2px solid transparent;
    min-width: 100%;
  }
}
</style>
